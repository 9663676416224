.short-label {
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.custom-svg-icon {
  position: relative;
  top: 1px;

  &.hover:hover {
    cursor: pointer;
    color: var(--color-pallet-primary-02);
  }
}

.custom-svg-icon-down {
  position: relative;
  top: 3px;
}

.light {
  .addproject-icon,
  .addrig-icon,
  .wellbore-icon,
  .addwell-icon,
  .setting-icon,
  .file-icon,
  .export-icon,
  .reup-icon,
  .eye-icon,
  .interval-icon,
  .run-icon,
  .edit-icon,
  .restore-icon,
  .activeStatus-icon,
  .inactiveStatus-icon,
  .check-icon,
  .open-icon,
  .cancel-icon {
    filter: brightness(0) saturate(100%) invert(25%) sepia(21%) saturate(562%) hue-rotate(169deg) brightness(96%) contrast(89%);
  }
}

.c-icons {
  width: 1rem;
  height: 1rem;
}

// START [COMMOM_PURE_CSS] _ ICON SVG
.addproject-icon {
  background-image: url("../../../assets/images/icons/svg-image/project.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.addrig-icon {
  background-image: url("../../../assets/images/icons/svg-image/rig.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.wellbore-icon {
  background-image: url("../../../assets/images/icons/svg-image/wellbore.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.addwell-icon {
  background-image: url("../../../assets/images/icons/svg-image/well.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.setting-icon {
  background-image: url("../../../assets/images/icons/svg-image/setting.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.file-icon {
  background-image: url("../../../assets/images/icons/svg-image/file.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.export-icon {
  background-image: url("../../../assets/images/icons/svg-image/export.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.reup-icon {
  background-image: url("../../../assets/images/icons/svg-image/reup.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.trash-bin-icon {
  background-image: url("../../../assets/images/icons/svg-image/trash-bin.svg");
  filter: brightness(0) saturate(100%) invert(14%) sepia(47%) saturate(5134%) hue-rotate(339deg) brightness(96%) contrast(104%);
  background-repeat: no-repeat;
  background-position: center center;
}

.eye-icon {
  background-image: url("../../../assets/images/icons/svg-image/eye.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.interval-icon {
  background-image: url("../../../assets/images/icons/svg-image/interval.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.run-icon {
  background-image: url("../../../assets/images/icons/svg-image/run.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.edit-icon {
  background-image: url("../../../assets/images/icons/svg-image/edit.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.restore-icon {
  background-image: url("../../../assets/images/icons/svg-image/restore.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.activeStatus-icon {
  background-image: url("../../../assets/images/icons/svg-image/activeStatus.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.inactiveStatus-icon {
  background-image: url("../../../assets/images/icons/svg-image/inactiveStatus.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.check-icon {
  background-image: url("../../../assets/images/icons/svg-image/check.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.open-icon {
  background-image: url("../../../assets/images/icons/svg-image/open.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.nominate-icon {
  background-image: url("../../../assets/images/icons/svg-image/nominate_icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.cancel-icon {
  background-image: url("../../../assets/images/icons/svg-image/cancel.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

// ========================================== // 

// START [COMMON_CUSTOM] p-dropdown
.custom-p-drop {
  .p-dropdown {
    height: 32px !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: $primary-font;
    border-radius: 4px;

    border: var(--color-pallet-primary-07) 1px solid;
    background-color: var(--color-pallet-primary-09);

    &.p-disabled span {
      color: var(--color-pallet-primary-06);
      border: none;
    }

    .p-dropdown-panel {
      width: inherit;
      background-color: var(--color-pallet-primary-09);

      .p-dropdown-items-wrapper {
        width: inherit;
        border-radius: 4px;
        border: 1px solid var(--color-pallet-primary-06);
      }

      .p-dropdown-header {
        margin: 0;
        padding: 0px 12px;
        height: 32px;
        background: var(--color-pallet-primary-10);
        border: 1px solid var(--color-pallet-primary-06);

        .p-dropdown-filter {
          display: flex;
          align-items: center;
          padding: 0px;
          height: 32px;
          width: 100%;
          background: transparent;
          border: none;
        }
      }

      .p-dropdown-items {
        font-family: $primary-font;
        padding: unset;

        .p-dropdown-item {
          height: 32px;
          font-size: 14px;
          color: var(--color-pallet-primary-18);
          padding: 0px 12px;
          border-radius: 4px;
          align-items: center;
          display: flex;

          &.p-disabled {
            color: var(--color-pallet-primary-06) !important;
            opacity: 1;

            .p-dropdown-label.p-inputtext {
              color: var(--color-pallet-primary-06);
              opacity: 1;
            }
          }

          // Hover State 
          &:not(.p-highlight):not(.p-disabled):hover {
            background: var(--color-pallet-primary-07);
            color: var(--color-pallet-primary-05);
          }

          &.p-highlight {
            background: var(--color-pallet-primary-07);
            color: var(--color-pallet-primary-05);
          }
        }

        // Empty filter
        .p-dropdown-empty-message {
          height: 32px;
          color: var(--color-pallet-primary-05);
          padding: 2px 12px;
          font-size: 14px;
          border-radius: 4px;
          margin: 0px;
          display: flex;
          align-items: center;
        }
      }
    }

    .p-dropdown-trigger {
      background-color: var(--color-pallet-primary-09);
    }

    .p-dropdown-label {
      font-family: $primary-font;
      color: var(--color-pallet-primary-04);
      font-size: 14px;
      font-weight: 500;
      border: unset !important;
      padding: 0px 12px !important;
      height: 40px !important;
      line-height: 40px !important;

      &.p-placeholder {
        color: var(--color-pallet-primary-05);
      }
    }

    // Focus outline
    &:not(.p-disabled).p-focus {
      box-shadow: unset;
    }
  }
}

.dropdown-wapper {
  width: 200px !important;

  span {
    color: var(--color-pallet-primary-04);
  }
}

// START [COMMON_CUSTOM] p-input
.custom-p-input {
  &.p-inputtext {
    font-family: $primary-font;
    color: var(--color-pallet-primary-03);
    background-color: var(--color-pallet-primary-09);
    border: 1px solid var(--color-pallet-primary-07);

    border-radius: 4px;

    height: 32px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding: 0px 12px;

    &:disabled {
      color: #3b4957;
    }
  }

  &.p-input-left-icon {
    &.p-inputtext {
      padding: 0px 32px 0px 12px
    }
  }
}

.p-inputtext:enabled:focus {
  border-color: #4d80ca !important;
  box-shadow: 0 0 0 0.02rem $color-pallet-primary-07 !important;
}

// ========================================== // 

// START [COMMON_CUSTOM] p-multiselect

.custom-p-textarea {
  &.p-inputtext {
    font-family: $primary-font;
    color: var(--color-pallet-primary-03);
    background-color: var(--color-pallet-primary-09);
    border: 1px solid var(--color-pallet-primary-07);
  }

  &:disabled {
    color: #3b4957;
  }
}

// ========================================== // 

// START [COMMON_CUSTOM] p-multiselect
.custom-p-multiselect {
  .p-multiselect {
    width: 100% !important;
    height: 32px !important;
    border-radius: 4px;
    background-color: var(--color-pallet-primary-09);
    border: 1px solid var(--color-pallet-primary-06);

    .p-inputtext {
      font-family: $primary-font;
      color: var(--color-pallet-primary-04);
      background-color: var(--color-pallet-primary-09);
      border: 1px solid var(--color-pallet-primary-06);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
    }

    .p-multiselect-label {
      font-size: 14px;
      height: 32px;
      padding: 0px 12px;
      display: flex;
      align-items: center;
      color: var(--color-pallet-primary-01);

      &.p-placeholder {
        color: var(--color-pallet-primary-04);
      }
    }

    .p-multiselect-panel .p-multiselect-header {
      background: var(--color-pallet-primary-09);
      border: 1px solid var(--color-pallet-primary-06);
      padding: 10px 12px;
      border-radius: 0px;
    }

    .p-multiselect-filter-container .p-inputtext {
      height: 32px;
    }

    .p-multiselect-panel {
      width: 100%;
      background: var(--color-pallet-primary-09);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
      background: var(--color-pallet-primary-09);
      color: var(--color-pallet-primary-02);
      font-size: 14px;
      border-radius: 4px;
      margin: 0;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
      background: var(--color-pallet-primary-07);
      color: var(--color-pallet-primary-02);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      font-size: 14px;
      padding: 6px 12px;
      color: var(--color-pallet-primary-02);

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .p-multiselect-panel .p-multiselect-items {
      padding: 0px;

      .p-multiselect-empty-message {
        padding: 6px 12px;
      }
    }

    .p-multiselect-items-wrapper {
      border: 1px solid var(--color-pallet-primary-06);
      border-radius: 4px;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      background: var(--color-pallet-primary-01);
      border-color: var(--color-pallet-primary-01);
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      color: var(--color-pallet-primary-11);
      font-size: 11px;
    }

    .p-checkbox .p-checkbox-box {
      width: 14px;
      height: 14px;
      border-radius: 0px;
      margin-top: 3px;
      background: transparent;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      background: var(--color-pallet-primary-01);
      border-color: var(--color-pallet-primary-01);
    }
  }

  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: unset;
  }
}

// ========================================== // 

// START [COMMON_CUSTOM] p-complete

.edit-form .smart-text-input {
  width: 100%;

  .p-inputtext {
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: var(--color-pallet-primary-04);
    background: transparent;
    border-color: var(--color-pallet-primary-06);
  }

  .p-autocomplete-panel {
    border: 1px solid var(--color-pallet-primary-06);
    background: var(--color-pallet-primary-09);
    border-radius: 4px;
    padding: 0px;
  }

  .p-autocomplete-panel .p-autocomplete-items {
    background: var(--color-pallet-primary-09);
    padding: 0px;
  }

  .result-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .add-new-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    color: var(--color-pallet-primary-02);
    padding: 10px;

    &:hover {
      color: var(--color-pallet-primary-02);
      background: var(--color-pallet-primary-07);
    }
  }

  .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background: var(--color-pallet-primary-07);
  }
}

// ========================================== // 

// START [COMMON_CUSTOM] p-treeselect 
.custom-p-tree {
  .p-treeselect {
    font-weight: 500;
    color: var(--color-pallet-primary-04);
    height: 32px;
    padding: 3px 0px 4px 10px;
    font-size: 14px;
    border: 1px solid var(--color-pallet-primary-06);
    width: 100% !important;
    background: var(--color-pallet-primary-09);
    border-radius: 4px;

    .p-treeselect-label {
      padding: unset;
      line-height: 24px;
      // color: var(--color-pallet-primary-04);
    }

    &:not(.p-disabled).p-focus {
      border-color: $--deep-primary;
      box-shadow: 0 0 0 0.2rem transparent;
    }

    .p-treeselect-items-wrapper {
      background: var(--color-pallet-primary-09);
      // max-height: 410px !important;
      border-radius: 4px;
      border: 1px solid var(--color-pallet-primary-06);
    }

    &:not(.p-disabled):hover {
      border-color: $--deep-primary;
    }
  }

  .p-treeselect-clearable .p-treeselect .p-treeselect-clear-icon {
    margin-right: 36px;
    right: 0;
  }

  .p-treeselect-clearable .p-treeselect .p-treeselect-trigger {
    padding-right: 20px;
    width: 0;
  }

  .p-tree {
    padding: 0px;

    .p-tree-wrapper .p-tree-container {
      overflow-x: hidden;
    }

    .p-tree-container .p-treenode {
      .p-treenode-content {
        padding: 2px 2px;
        font-size: 14px;
        position: relative;
        height: 32px;
        padding-left: 42px;
      }

      .p-treenode-content .p-tree-toggler {
        color: var(--color-pallet-primary-04);
        margin-right: 0px;

        position: absolute;
        padding-right: 100%;
        left: -15px;
        padding-left: 28px;
        display: block;
        padding-bottom: 3px;
        margin-top: -2px;

        //
        span {
          font-size: 12px;
        }
      }

      color: var(--color-pallet-primary-18);
      background: var(--color-pallet-primary-09);

      .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-18);
      }

      .p-treenode-content.p-highlight {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-18);
      }

      .p-treenode-content .p-tree-toggler:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }

      &.parent>div:hover {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-18);
      }

      &.parent>div>div.p-checkbox {
        display: none;
      }
    }

    .p-treenode-content .p-tree-toggler:enabled:hover {
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  .p-tree-container .p-treenode.lowestChild .p-treenode-content .p-tree-toggler {
    color: var(--color-pallet-primary-06);
    padding-right: 28px;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    align-items: center;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: transparent;
  }

  .p-checkbox .p-checkbox-box {
    border: 2px solid var(--color-pallet-primary-02);
    background: transparent;
    width: 14px;
    height: 14px;
    color: var(--color-pallet-primary-10);
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

    &.p-highlight {
      background: var(--color-pallet-primary-02);

      .p-checkbox-icon {
        color: var(--color-pallet-primary-10);
        font-weight: bold;
      }
    }

    .p-checkbox-icon {
      font-size: 10px;
    }
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree .p-tree-empty-message {
    padding: 6px 12px;
    color: var(--color-pallet-primary-18);
    background: var(--color-pallet-primary-09);
  }
}

.custom-p-tree.without-parent {
  .p-tree {
    .p-tree-container .p-treenode {
      .p-treenode-content {
        padding-left: 12px;
      }
    }
  }
}

// ========================================== // 

// START [COMMON_CUSTOM] p-treeSelect
.custom-multiple-level {
  .p-treeselect {
    font-weight: 500;
    color: var(--color-pallet-primary-04);
    height: 32px;
    padding: 3px 0px 4px 10px;
    font-size: 14px;
    border: 1px solid var(--color-pallet-primary-06);
    width: 100%;
    background: var(--color-pallet-primary-09);
    border-radius: 4px;

    .p-treeselect-label {
      padding: unset;
      line-height: 24px;
      color: var(--color-pallet-primary-04);

      &.p-placeholder {
        color: var(--color-pallet-primary-05);
      }
    }

    &:not(.p-disabled).p-focus {
      border-color: $--deep-primary;
      box-shadow: 0 0 0 0.2rem transparent;
    }

    .p-treeselect-items-wrapper {
      background: var(--color-pallet-primary-09);
      border-radius: 4px;
      border: 1px solid var(--color-pallet-primary-06);
    }

    &:not(.p-disabled):hover {
      border-color: $--deep-primary;
    }
  }

  .p-tree {
    padding: 0px;
    background: transparent;

    .p-tree-wrapper .p-tree-container {
      overflow-x: hidden;
    }

    .p-tree-container .p-treenode {
      color: var(--color-pallet-primary-18);
      background: var(--color-pallet-primary-09);

      .p-treenode-content {
        padding: 2px 2px;
        font-size: 14px;
        // fix bug dropdown
        position: relative;
        height: 32px;
        padding-left: 42px;
        //

        &:focus {
          box-shadow: unset;
        }
      }

      .p-treenode-content .p-tree-toggler {
        color: var(--color-pallet-primary-04);
        margin-right: 0px;

        // fix bug dropdown
        position: absolute;
        padding-right: 100%;
        left: -15px;
        padding-left: 28px;
        display: block;
        padding-bottom: 3px;
        // height: 77px;
        margin-top: -2px;

        //
        span {
          font-size: 12px;
        }
      }

      .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-05);
      }

      .p-treenode-content.p-highlight {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-05);
      }

      .p-treenode-content .p-tree-toggler:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }

      &.parent>div:hover {
        background: var(--color-pallet-primary-07);
        color: var(--color-pallet-primary-05);
      }

      &.parent>div>div.p-checkbox {
        display: none;
      }

      &.single-choose {
        .p-treenode-content {
          padding-left: 16px;

          .p-checkbox {
            margin-right: 14px;
          }
        }
      }
    }

    .p-treenode-content .p-tree-toggler:enabled:hover {
      color: inherit !important;
      border-color: unset !important;
      background: unset !important;
      //
    }
  }

  .p-tree .p-treenode-children {
    padding-left: 0px;
  }

  .p-tree .p-treenode-children {
    padding-left: 16px;
  }

  .p-tree-container .p-treenode.lowestChild .p-treenode-content .p-tree-toggler {
    color: var(--color-pallet-primary-06);
    padding-right: 28px;
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree .p-tree-empty-message {
    padding: 6px 12px;
    color: #495057;
    background: transparent;
  }

  .p-checkbox {
    height: unset;
    width: unset;

    .p-checkbox-box {
      border: 2px solid var(--color-pallet-primary-02);
      background: transparent;
      width: 14px;
      height: 14px;
      color: var(--color-pallet-primary-10);
      border-radius: 2px;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;

      &.p-highlight {
        background: var(--color-pallet-primary-02);

        .p-checkbox-icon {
          color: var(--color-pallet-primary-10);
          font-weight: bold;
          font-size: 10px;
        }
      }
    }
  }
}


// ========================================== // 


// START [COMMON_CUSTOM] p-button 
.custom-p-button {
  &--primary {
    .p-button {
      border-color: var(--color-pallet-primary-07);
      background: var(--color-pallet-primary-03);
      color: var(--color-pallet-primary-07);

      &:hover {
        border-color: var(--color-pallet-primary-07);
        background: var(--color-pallet-primary-02);
        color: var(--color-pallet-primary-07);
      }
    }
  }

  &--secondary {
    .p-button {
      border-color: var(--color-pallet-primary-07);
      background: var(--color-pallet-primary-07);
      color: var(--color-pallet-primary-02);

      &:hover {
        border-color: var(--color-pallet-primary-07);
        background: var(--color-pallet-primary-06);
        color: var(--color-pallet-primary-02);
      }
    }
  }

  .p-button {
    font-family: $primary-font;
    height: 32px;
    border-radius: 4px;
    min-width: 72px;
    padding: 0px;

    .p-button-label {
      font-size: 14px;
      padding: 0 12px;
    }
  }
  .p-button:focus {
    box-shadow: none;
  }

  &.custom-p-button--disable .p-button {
    opacity: 0.6 !important;
  }
}

.secondary-disabled .custom-p-button {
  .p-button {
    font-family: $primary-font;
    height: 32px;
    border-color: var(--color-pallet-primary-07);
    background: var(--color-pallet-primary-09);
    color: var(--color-pallet-primary-06);

    &:hover {
      border-color: var(--color-pallet-primary-07);
      background: var(--color-pallet-primary-09);
      color: var(--color-pallet-primary-06);
    }
  }
}

// ========================================== // 

// START [COMMON_CUSTOM] p-button 

.custom-p-checkbox {
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: transparent;
  }

  .p-checkbox .p-checkbox-box {
    border: 2px solid var(--color-pallet-primary-02);
    background: transparent;
    width: 14px;
    height: 14px;
    color: var(--color-pallet-primary-10);
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

    &.p-highlight {
      background: var(--color-pallet-primary-02) !important;

      .p-checkbox-icon {
        color: var(--color-pallet-primary-10);
        font-weight: bold;
      }
    }

    .p-checkbox-icon {
      font-size: 10px;
    }
  }

  .p-checkbox {
    height: unset;
    width: unset;

    &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      box-shadow: 0 0 0 0.2rem transparent;
    }
  }

  &.bold {
    font-size: 14px;
    font-weight: 500;

    .p-checkbox .p-checkbox-box {
      border: 3px solid var(--color-pallet-primary-02);
      width: 16px;
      height: 16px;
    }
  }
}

// ========================================== // 


// START [COMMOM_PURE_CSS] _ ERROR TEXT
.inner-msg-error,
.inner-msg-warning {
  font-size: 12px;
  margin-top: 4px;
  padding: 0px;
  font-weight: 500;
}

.inner-msg-error span {
  color: $--color-error;
}

.inner-msg-warning span {
  color: $--color-warning;
}

// START [COMMOM_PURE_CSS] _ TEXT

.primary-color {
  color: $--color-primary;

  &:hover {
    color: var(--color-pallet-primary-20);
  }
}

.form-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-pallet-primary-04);
}

.inner-description-text {
  margin-top: 6px;
  padding: 0px;

  span {
    color: var(--color-pallet-primary-06);
    font-size: 12px;
    font-weight: 500;
  }
}

.label-disabled {
  color: var(--color-pallet-primary-06) !important;
  opacity: 0.6;
}

.error-border {
  border: 1px solid $--color-error !important;
}

// ========================================== // 

// START [COMMOM_PURE_CSS] _ SEARCH BOX
.search-box {
  height: 32px;
  width: 320px;
}

.search-box.p-inputtext {
  font-family: $primary-font;
  color: var(--color-pallet-primary-04);
  background-color: var(--color-pallet-primary-09);

  border-radius: 4px;
  border: 1px solid var(--color-pallet-primary-06);

  font-size: 14px;
  font-weight: 500;

  &:disabled {
    color: #3b4957;
    opacity: 1;
  }
}

// ========================================== // 

// ========================================== // 

// START [COMMOM_CUSTOM] p-toaster
.custom-toaster {
  .p-toast-top-right {
    right: -8px !important; // right: -14px !important;
    top: 56px !important; // top: 50px !important; --> i changed it form 50px -> 56px for requirements spacing 16px like AC after remediate layout.
  }

  .p-toast .p-toast-message,
  .p-toast .p-toast-message {
    background: transparent;
    border: none;
    color: $color-pallet-primary-09;
    width: 375px;

    .p-toast-icon-close,
    .p-toast-message-icon {
      .p-toast-icon-close-icon {
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: bold;
      }
    }

    .p-toast-icon-close:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: unset;
    }

    .p-toast-icon-close:hover {
      background: transparent;
    }
  }

  .p-toast {
    opacity: 1; // <-- fix opacity of Toast
    z-index: 9500 !important;
    font-size: 14px;
    line-height: 20px;

    .p-toast-message .p-toast-message-content {
      background: $--color-success;
      padding: 16px 12px 16px 12px;
      justify-content: space-between;

      .header-notification {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: $color-pallet-primary-09;

        .sumary-text {
          margin-bottom: 2px;
          margin-left: 12px;
          font-weight: 700;
          text-transform: capitalize;
        }
      }

      .detail-notification {
        .detail-text {
          font-weight: 500;
          margin-bottom: 12px;
          color: $color-pallet-primary-06;
          word-break: break-word;
        }

        .time-text {
          font-weight: 400;
          font-size: 12px;
          color: $color-pallet-primary-06;
        }
      }
    }

    .p-toast-message-success,
    .p-toast-message-warning {
      .p-toast-icon-close {
        color: $color-pallet-primary-09;
      }
    }

    .p-toast-message-info {
      .p-toast-message-content {
        background: $color-hover-blue-dark;

        .header-notification {
          color: $color-pallet-primary-02;
        }

        .detail-notification {
          .detail-text {
            color: $color-pallet-primary-04;
          }

          .time-text {
            color: $color-pallet-primary-04;
          }
        }
      }

      .p-toast-icon-close {
        color: $color-pallet-primary-02;
      }
    }

    .p-toast-message-error {
      .p-toast-message-content {
        background: $--color-error;

        .header-notification {
          color: $color-pallet-primary-02;
        }

        .detail-notification {
          .detail-text {
            color: $color-pallet-primary-04;
          }

          .time-text {
            color: $color-pallet-primary-04;
          }
        }
      }

      .p-toast-icon-close {
        color: $color-pallet-primary-02;
      }
    }

    .p-toast-message-warning {
      .p-toast-message-content {
        background: $--color-warning;

        .header-notification {
          color: $color-pallet-primary-09;
        }

        .detail-notification {
          .detail-text {
            color: $color-pallet-primary-06;
          }

          .time-text {
            color: $color-pallet-primary-06;
          }
        }
      }
    }
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-tooltip
.p-tooltip-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: $primary-font;
}

.p-tooltip-arrow {
  border-top-color: var(--color-pallet-primary-08) !important;
  border-bottom-color: var(--color-pallet-primary-08) !important;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 50%) !important;
}

.p-tooltip .p-tooltip-text {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 50%) !important;
  border-radius: 4px !important;
  background: var(--color-pallet-primary-08) !important;
  padding: 10px 12px !important;
  color: var(--color-pallet-primary-04) !important;
  text-align: center !important;
}

// ========================================== // 

// START [COMMOM_CUSTOM] tooltip
.tooltipText {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background: var(--color-pallet-primary-08);
  padding: 8px 12px !important;
  position: absolute;
  display: inline-block !important;
  visibility: hidden;
  top: calc(100% + 20px);
  font-weight: 400;
  font-size: 14px;
  color: var(--color-pallet-primary-04);
  z-index: 9999;
  text-align: center;
  width: 110%;
  white-space: wrap;

  &.tooltipText {
    width: auto;
    white-space: nowrap;
  }

  &.title {
    left: 18px;
  }

  &.button {
    left: -58%;
  }

  &.button-right {
    right: -35px;
  }

  &.button-right-default {
    right: -5px;

    &::before {
      left: calc(85% - 5px);
    }
  }

  &.visible {
    visibility: visible;
  }

  &.tooltipText {
    width: auto;
    white-space: nowrap;
  }

}

.tooltipText:before {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  background: var(--color-pallet-primary-08);
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.5);
}

.tooltipText.button-right:before {
  left: calc(83% - 5px);
}

.tooltipText.button-bottom {
  left: -50px;
}

// ========================================== // 

// START [COMMOM_CUSTOM] Form Dialog
.style-class-dialog.p-dialog {
  background: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-09);
  box-shadow: 0px 0px 4px rgba(129, 136, 137, 0.12);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: $primary-font;

  .custom-close-icon {
    padding-right: 2px;
    cursor: pointer;
  }

  .p-dialog-header {
    font-size: 20px;
    font-weight: 600;

    padding: 16px 24px 10px 24px;

    color: var(--color-pallet-primary-02);
    background: var(--color-pallet-primary-09);

    // Remove if break app
    align-items: flex-start;
    line-height: 28px;
  }

  .p-inputwrapper-focus {
    .p-dropdown {
      border-color: #3b82f6 !important;
    }
  }

  .p-dialog-content {
    font-size: 14px;
    font-weight: 500;

    padding: 0px 24px;

    color: var(--color-pallet-primary-18);
    background: var(--color-pallet-primary-09);

    overflow: unset;

    .add-input-des {
      color: var(--color-pallet-primary-05);
      line-height: 18px;
    }
  }

  .p-dialog-footer {
    padding: 16px 24px;
    background: var(--color-pallet-primary-09);
  }

  .p-dialog-header-icons {
    display: none;
  }
}

.style-class-dialog-custom.p-dialog {
  font-family: $primary-font;
  border: 1px solid var(--color-pallet-primary-09);
  font-size: 20px;
  font-weight: 600;

  .p-dialog-header,
  .p-dialog-content {
    padding: 16px 24px;
    color: var(--color-pallet-primary-02);
    background: var(--color-pallet-primary-09);
    overflow: visible; // FIX Overflow dropdown inside
  }

  .p-dialog-header-icon:enabled:hover {
    background: transparent;
  }
  .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
  }

  .p-dialog-header-icon {
    span {
      font-size: 16px;
      font-weight: 900;
    }
  }
  .p-dialog-footer {
    padding: 16px 24px;
    background: var(--color-pallet-primary-09);
  }
}

:host::ng-deep {
  .p-dialog .p-dialog-footer button {
    margin: 0px;
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-calendar
.p-calendar-clearable .custom-p-calendar.secondary-icon .p-calendar-clear-icon {
  margin-right: 30px;
}

.custom-p-calendar.p-calendar {
  display: flex !important;

  .p-inputtext {
    background-color: var(--color-pallet-primary-09);
    color: var(--color-pallet-primary-04);
    border: 1px solid var(--color-pallet-primary-07);
    height: 32px !important;
    padding-left: 32px !important;
    border-radius: 6px;
  }

  .p-datepicker-trigger.p-button {
    width: auto;
    height: 32px;
    position: absolute;
    background: transparent;
    border: none;
    padding: 0px 0px 0px 10px;
    color: var(--color-pallet-primary-04);
  }

  .p-datepicker {
    transform: scale(0.8);
    background-color: var(--color-pallet-primary-08);
    color: var(--color-pallet-primary-04);

    .p-datepicker-header {
      color: var(--color-pallet-primary-03);
      background-color: var(--color-pallet-primary-09);
    }

    .p-datepicker-today {
      span {
        background: var(--color-pallet-primary-06);
        color: var(--color-pallet-primary-04);
        border-color: transparent;
      }
    }

    .p-timepicker button {
      border-radius: 50% !important; // Need define another style css to prevent override
    }

    .p-datepicker-header .p-datepicker-prev:enabled:hover,
    .p-datepicker-header .p-datepicker-next:enabled:hover {
      color: var(--color-pallet-primary-02); // Same color both theme
      background: var(--color-select-blue-dark);
      border-color: transparent;
    }

    &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
    &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus,
    .p-link:focus,
    table td>span:focus {
      box-shadow: 0 0 0 0.2rem transparent !important;
    }

    // style month and year buttons
    .p-monthpicker .p-monthpicker-month.p-highlight,
    .p-yearpicker .p-yearpicker-year.p-highlight {
      color: var(--color-pallet-primary-04);
      background: var(--color-select-blue-dark);
      border-radius: 5px;
    }

    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
    &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover,
    &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
      background: #445d76;
    }
  }
}

.custom-p-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #445d76;
}

.custom-p-calendar .p-datepicker table td>span.p-highlight {
  font-weight: 800;
  color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-03);
}


.custom-p-calendar.time-range-calendar.p-calendar {
  display: flex !important;

  .p-datepicker {
    background-color: var(--color-pallet-primary-08);
    color: var(--color-pallet-primary-04);

    .p-datepicker-header {
      color: var(--color-pallet-primary-03);
      background-color: var(--color-pallet-primary-09);
    }

    .p-datepicker-today {
      span {
        background: var(--color-pallet-primary-06);
        color: var(--color-pallet-primary-04);
        border-color: transparent;
      }
    }
  }

  .p-datepicker-trigger.p-button {
    padding: unset;
  }
}

.custom-p-calendar.time-range-calendar {
  height: 32px;

  .p-inputtext {
    padding-left: 10px !important;
    border-radius: 6px 0px 0px 6px;
    background: var(--ccolor-pallet-primary-09);
    border: 1px solid var(--color-pallet-primary-06);
    font-size: 14px;
  }

  .p-datepicker-trigger.p-button {
    position: relative !important;
    background: var(--color-pallet-primary-04);
    color: var(--color-pallet-primary-09);
    border: 1px solid var(--color-pallet-primary-06);
    width: 37px;

    .p-button-icon {
      background: var(--color-pallet-primary-04);
      font-weight: 600;
    }
  }

  &.p-calendar .p-calendar-clear-icon {
    right: 48px;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
  .p-link:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
  }

  &.export .p-datepicker {
    transform: scale(1);
    left: -23px !important;
  }

  .p-datepicker {
    transform: scale(1);
    left: 0px !important;

    // style month and year buttons
    .p-monthpicker .p-monthpicker-month.p-highlight,
    .p-yearpicker .p-yearpicker-year.p-highlight {
      color: var(--color-pallet-primary-04);
      background: $color-select-blue-dark;
      border-radius: 5px;
    }

    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
    &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover,
    &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
      background: #445d76;
    }
  }

  .p-datepicker-title button {
    color: var(--color-pallet-primary-02) !important;
    font-size: 16px !important;
    font-weight: 700 !important;

    &.p-datepicker-month {
      margin-right: 0px;
    }
  }

  .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: $color-pallet-primary-02; // Same color both theme
    background: $color-select-blue-dark;
    border-color: transparent;
  }

  table td {
    padding: 2px;
  }

  table td>span {
    width: 44px;
    height: 44px;

    &.p-highlight {
      color: $color-pallet-primary-02; // Same color both theme
      background: $color-select-blue-dark;
      border-radius: 5px;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    border-radius: 5px;
  }

  .p-datepicker .p-datepicker-today span {
    border-radius: 5px;
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-chip
:host::ng-deep {
  .chip-wrapper {
    margin: 0px 10px;

    .p-chip {
      height: 20px;

      margin-right: 6px;
      margin-top: 9px;

      border-radius: 4px;

      background: var(--color-pallet-primary-07);
    }

    .p-chip-text {
      max-width: 150px;

      font-size: 14px;
      font-weight: 500;

      color: var(--color-pallet-primary-05);

      margin-top: 0px;
      margin-bottom: 0px;

      line-height: 18px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .p-chip .pi-chip-remove-icon {
      color: var(--color-pallet-primary-05);

      font-size: 13px;

      line-height: 18px;

      margin-top: 2px;

      &:hover {
        color: var(--color-pallet-primary-04);
      }

      &:focus {
        box-shadow: unset !important;
      }
    }
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-dynamicdialog
::ng-deep {
  .p-dynamic-dialog.fullscreen-popup .p-dialog-content {
    width: 90vw;
    padding: 0px;
    margin-left: 40px;
    border-radius: 6px;
    user-select: none;
    background-color: var(--color-pallet-primary-10);
  }

  .p-dynamic-dialog.hide-dialog .p-dialog-content {
    background: transparent;
    margin: 0 auto;
  }
}

.header-dialog {
  margin-left: 7px;
  margin-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-menu OverLay
.p-overlaypanel .p-overlaypanel-content {
  padding-top: 0px;
}

// User for overide with of p-menu <-- Important need to move when migrations
.p-menu {
  &.p-menu-overlay {
    background: var(--color-pallet-primary-09);
    margin-top: 10px;
    margin-left: 6px;
    width: 170px;
    border: 1px solid var(--color-pallet-primary-06);
  }

  .p-menuitem-link {
    padding: 8px 12px;
    border: 1px solid var(--color-pallet-primary-09);

    &:not(.p-disabled):hover {
      background: var(--color-pallet-primary-07);
      border: 1px solid var(--color-pallet-primary-07);

      .p-menuitem-text {
        color: var(--color-pallet-primary-04);
      }
    }

    &:focus {
      box-shadow: unset;
      border: 1px solid $--color-primary !important;
    }
  }

  .p-submenu-header {
    display: none;
  }

  .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-pallet-primary-04);
  }

  .p-menuitem.red-label .p-menuitem-link .p-menuitem-text {
    color: var(--color-pallet-primary-15);
  }

  .p-menuitem.red-label .p-menuitem-link {
    &:not(.p-disabled):hover {
      .p-menuitem-text {
        color: var(--color-pallet-primary-15);
      }
    }
  }
}

.p-menu.p-menu-overlay {
  background: var(--color-pallet-primary-09);
  padding: 0px;
  width: 134px;
  border: 1px solid var(--color-pallet-primary-06) !important;

  &.menu-table-item.lower-item {
    margin-top: -16px;
  }

  .p-menuitem-link {
    padding: 10px 12px;
    border-radius: 6px;
  }

  &.auto-item {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.extend-md-width-menu.p-menu.p-menu-overlay {
  width: 182px;
}

.md-width-menu.p-menu.p-menu-overlay {
  width: 170px;
}

.lg-width-menu.p-menu.p-menu-overlay {
  width: 240px;
}

.auto-width-menu.p-menu.p-menu-overlay {
  width: auto !important;
}

.inherit.p-menu.p-menu-overlay {
  margin-top: 0;
  margin-left: 0;
  width: 125px;
}

.sm-width-menu.p-menu.p-menu-overlay {
  padding: 0px;
  width: 118px;

  ul {
    li {
      a {
        border-radius: 4px 4px 0 0;
        border: 1px solid transparent;
      }
    }

    li:last-child {
      a {
        border-radius: 0 0 4px 4px;
        border: 1px solid transparent;
      }
    }
  }
}

.p-submenu-header {
  display: none;
}

.p-menu {
  .p-menuitem.red-label .p-menuitem-link .p-menuitem-text {
    color: $--color-error;
  }

  .p-menuitem.red-label .p-menuitem-link {
    &:not(.p-disabled):hover {
      .p-menuitem-text {
        color: $--color-error;
      }
    }
  }

  .p-menuitem.yellow-label .p-menuitem-link .p-menuitem-text {
    color: $--color-warning;
  }

  .p-menuitem.yellow-label .p-menuitem-link {
    &:not(.p-disabled):hover {
      .p-menuitem-text {
        color: $--color-warning;
      }
    }
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-table

.more-option-table {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;

  &.active {
    border: 1px solid $--color-primary;
  }

  &.disabled {
    cursor: default;
    color: var(--color-pallet-primary-05);
  }
}

.more-option-title {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;

  &.active {
    border: 1px solid $--color-primary;
  }
}

.menu-table-item {
  margin-top: -16px;
}

.custom-table {
  &.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem transparent;
    outline: 0 none;
  }

  &.p-datatable .p-sortable-column:hover {
    background: var(--color-pallet-primary-07);
    color: var(--color-pallet-primary-05);
  }

  word-break: break-word;
  margin-bottom: 12px;

  &.p-datatable {
    .p-datatable-thead>tr>th {
      background: var(--color-pallet-primary-07);
      color: var(--color-pallet-primary-05);
      font-size: 14px;
      font-weight: 500;
      border-color: transparent;
      border-right: 2px solid var(--color-pallet-primary-11);

      display: flex;
      justify-content: space-between;

      &[aria-sort="ascending"] .pi-sort-amount-up-alt:before {
        color: $--color-primary;
      }

      &[aria-sort="descending"] .pi-sort-amount-down:before {
        color: $--color-primary;
      }
    }

    .p-datatable-thead>tr>th:last-child {
      border-right: none;
    }

    .p-datatable-tbody>tr>td {
      color: var(--color-pallet-primary-04);
      font-size: 14px;
      font-weight: 500;
      border-color: transparent;
      padding: 14px 12px;

      display: inline-block;
      width: 0px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;

      &.td-feature {
        display: flex;
        align-items: center;
        margin-top: 9px;
        gap: 4px;

        &.text-error {
          color: $--color-error;
        }

        &.text-warning {
          color: $--color-warning;
        }

        .td-content {
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          margin-right: 6px;
        }

        .td-icon {
          svg-icon {
            color: var(--color-pallet-primary-05);
          }
        }

        span.text-error-icon {
          margin-top: 2px;
          color: $--color-error;
        }

        span.text-warning-icon {
          margin-top: 2px;
          color: $--color-warning;
        }
      }

      &:hover .tooltipText {
        visibility: visible;
      }

      &.td-option {
        max-width: 50px;
      }

      &.td-default {
        min-width: 80px;
      }

      &.td-medium {
        min-width: 240px;
      }

      &.td-large {
        min-width: 320px;
      }

      &.td-long {
        min-width: 380px;
      }

      .text-active {
        color: $--color-success;
      }

      .text-inactive {
        color: $--color-error;
      }
    }

    .p-datatable-tbody>tr {
      background: var(--color-pallet-primary-10);
      cursor: pointer;
      height: 64px;
      position: relative; // <== check

      &:not(.p-highlight):hover {
        background: var(--color-pallet-primary-08) !important;
      }
    }
  }

  &.p-datatable-striped .p-datatable-tbody tr:nth-child(even) {
    background: var(--color-pallet-primary-08);
  }

  .p-datatable-wrapper {
    height: 100vh;
    max-height: calc(100vh - 292px);
  }

  &.p-datatable .p-datatable-tbody>tr>td.primary-color {
    color: $--color-primary;
  }

  &.p-datatable .p-datatable-thead>tr>th {
    .pi-sort-amount-up-alt:before {
      font-family: "Material Icons";
      content: "\e5ce";
      color: var(--color-pallet-primary-05);
      font-weight: 900;
    }

    .pi-sort-amount-down:before {
      font-family: "Material Icons";
      content: "\e5cf";
      color: var(--color-pallet-primary-05);
      font-weight: 900;
    }
  }

  &.p-datatable {
    .p-sortable-column th {
      display: flex;
      justify-content: space-between;
    }

    .p-sortable-column.th-last {
      max-width: 50px;
      min-width: 50px;
    }

    .p-sortable-column.th-document {
      min-width: 100px;
      max-width: 140px;
      justify-content: center;
    }

    .p-sortable-column.th-document-sort {
      max-width: 94px;
      justify-content: center !important;
    }

    .p-sortable-column.th-document-long {
      min-width: 380px;
    }

    .p-sortable-column.th-document-large {
      min-width: 320px;
    }

    .p-sortable-column.th-document-medium {
      min-width: 240px;
    }

    .p-sortable-column.th-document-small {
      min-width: 140px;
    }

    .p-sortable-column.th-document-role {
      display: flex;
      justify-content: space-between;
      min-width: 220px;
    }

    .p-sortable-column.th-first {
      min-width: 318px;
    }

    .p-sortable-column.th-first-left {
      max-width: 50px;
    }

    .p-sortable-column.th-default {
      min-width: 80px;
    }

    .p-sortable-column {
      .th-content {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        margin-right: 6px;
      }

      .header-sort-icon {
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        .pi-sort-amount-up-alt {
          margin-top: 2px;
        }

        .pi-sort-amount-down {
          margin-top: -7px;
        }
      }
    }
  }
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-badge
.p-overlay-badge .p-badge {
  font-size: 12px;
  height: 18px;
  line-height: 19px;
  padding: 0px 6px;
  margin-top: 2px;
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-skeleton
.p-skeleton {
  background-color: var(--color-pallet-primary-09);
}

.p-skeleton:after {
  background: linear-gradient(
    90deg, 
    var(--color-pallet-primary-08), 
    var(--color-pallet-primary-09-1), 
    var(--color-pallet-primary-08), 
  );
}

// ========================================== // 

// START [COMMOM_CUSTOM] p-tabview

.tabview-content__form {
  border-right: 1px solid var(--color-pallet-primary-11);
  overflow: auto;
  height: 100%;
}

.tabview-content {
  .p-tabview-nav-container {
    margin-bottom: 6px;

    .p-tabview-nav {
      background: transparent;
      border: none;

      li.p-highlight {
        a {
          color: var(--color-pallet-primary-02);
          background: transparent;
          border: none;

          span {
            border-bottom: 1px solid $Progress-And-Line;
            padding-bottom: 6px;
          }
        }
      }

      li {
        a {
          background: transparent;
          padding: 16px 16px 8px 0;

          span {
            font-size: 12px;
          }
        }

        .p-tabview-nav-link {
          border: none;
          color: var(--color-pallet-primary-05);
        }

        .p-tabview-nav-link:not(.p-disabled):focus {
          box-shadow: none;
        }

        // &.p-disabled {
        //   a {
        //     color: var(--color-pallet-primary-05);
        //   }
        // }
      }

      li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: transparent;
      }
    }
  }
}

.tabview-content-wrapper {
  .p-tabview {
    font-size: 14px;

    .tabview-content__form--INITIATED {
      font-size: 12px;
    }

    .p-tabview-panels {
      background: transparent;
      color: var(--color-pallet-primary-05);
      padding: 0px;
      min-height: 260px;
    }
  }
}

.progressUpload {
  height: 4px;
  background: var(--color-pallet-primary-07);

  .p-progressbar-value {
    background: $Progress-And-Line;
  }
}

.in-unit::after {
  content: "in";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.ft-unit::after {
  content: "ft";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.mm-unit::after {
  content: "mm";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.m-unit::after {
  content: "m";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.non-icon {

  &.in-unit::after,
  &.mm-unit::after,
  &.ft-unit::after,
  &.m-unit::after {
    right: 12px;
  }
}

.unit-disabled {

  &.in-unit::after,
  &.mm-unit::after,
  &.m-unit::after,
  &.ft-unit::after {
    color: #28343f;
  }
}


// loading
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



// error common
p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
  border-color: $--color-error;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: $--color-error !important;
}

.p-inputtext.ng-touched.ng-invalid {
  border-color: $--color-error !important;
}

p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
  border-color: $--color-error;
}

p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
  border-color: $--color-error;
}

p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
  border-color: $--color-error;
}

p-treeselect.ng-invalid.ng-dirty>.p-treeselect {
  border-color: $--color-error;
}

p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
  border-color: $--color-error;
}